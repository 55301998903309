
  import { Component, Vue } from "vue-property-decorator";
  import LzButton from "@/components/Button.vue";
  import LzTable from "@/components/Table.vue";
  import LzConfirm from "@/components/Confirm.vue";
  import { namespace } from "vuex-class";
  import { api } from "../api";
  import LzEditorInput from "@/components/EditorInput.vue";
  import { ROUTES } from "../router";
  import { getImgURL } from "@/utils/getFormulateImageUrl";

  const auth = namespace("auth");

  @Component({ components: { LzButton, LzTable, LzConfirm, LzEditorInput } })
  export default class EventsCreate extends Vue {
    loaded = false;
    eventId = "";

    @auth.State("id")
    memberId!: string;

    statusOptions = {
      enabled: this.$t("events.create.generalForm.statusOptions.enabled"),
      disabled: this.$t("events.create.generalForm.statusOptions.disabled")
    };

    locationTypeOptions = {
      onsite: this.$t("events.create.generalForm.typeOptions.onsite"),
      online: this.$t("events.create.generalForm.typeOptions.online")
    };

    form: CalendarEventForm = {
      name: "",
      description: "",
      dates: [
        {
          inscription_start: "",
          inscription_end: "",
          event_start: "",
          event_end: ""
        }
      ],
      main_image: "",
      images: [],
      location: "online",
      tickets: [],
      url: ""
    };

    locationOptions = {
      Presencial: this.$t("events.create.generalForm.modeOptions.onsite"),
      Online: this.$t("events.create.generalForm.modeOptions.online")
    };

    async getEvent(eventId: string) {
      const event = await api.get(eventId);
      const images = event.images.reduce(
        (acc, img) => {
          if (img.default) acc.main = img.url;
          else acc.other.push({ url: img.url });
          return acc;
        },
        { main: "", other: [] as { url: string }[] }
      );

      this.form = {
        name: event.name,
        description: event.description,
        dates: [
          {
            inscription_start: event.dates.inscription_start,
            inscription_end: event.dates.inscription_end,
            event_start: event.dates.event_start,
            event_end: event.dates.event_end
          }
        ],
        main_image: [{ url: images.main }],
        images: images.other,
        location: event.location,
        tickets: event.tickets,
        url: event.url
      };
    }

    async createEvent(body: CalendarEventPostBody) {
      try {
        await api.create(body);
        this.$notify({
          type: "success",
          text: this.$tc("events.create.notifications.createdEvent")
        });
        this.$router.push({ name: ROUTES.READ });
      } catch (error) {
        this.$notify({
          type: "error",
          text: this.$tc("common.error.generic")
        });
      }
    }

    async updateEvent(body: Partial<CalendarEvent>) {
      try {
        await api.update(this.eventId, body);
        this.$notify({
          type: "success",
          text: this.$tc("events.create.notifications.editedEvent")
        });
        this.$router.push({ name: ROUTES.READ });
      } catch (error) {
        this.$notify({
          type: "error",
          text: this.$tc("common.error.generic")
        });
      }
    }

    async mounted() {
      this.eventId = this.$route.params.eventId;

      try {
        if (!this.eventId) {
          this.loaded = true;
          return;
        }
        // get event data
        await this.getEvent(this.eventId);
        this.loaded = true;
      } catch (error) {
        this.$notify({
          type: "error",
          text: this.$tc("common.error.generic")
        });
      }
    }

    onCancel() {
      this.$router.push({ name: ROUTES.READ });
    }

    async onSave(event: CalendarEventForm) {
      const images: CalendarEventPostBody["images"] = [];

      if (Array.isArray(event.main_image)) {
        images.push({
          default: true,
          url: (await getImgURL(this.form.main_image)) as string
        });
      }
      if (Array.isArray(event.images)) {
        images.push(
          ...event.images.map(({ url }) => ({
            default: false,
            url
          }))
        );
      }

      const body: CalendarEventPostBody = {
        name: this.form.name,
        member_id: this.memberId,
        description: this.form.description,
        dates: this.form.dates[0],
        images,
        location: this.form.location,
        tickets: this.form.tickets,
        url: this.form.url
      };

      if (this.eventId) this.updateEvent(body);
      else this.createEvent(body);
    }
  }
